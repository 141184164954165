<template>
  <ul
    role="list"
    class="divide-y divide-gray-200"
  >
    <TrayListItem
      v-for="item in items"
      :key="item.id"
      :item="item"
      @abortUpload="$emit('abort-upload', $event)"
      @uploadError="$emit('upload-error', $event)"
      @uploadComplete="$emit('upload-complete', $event)"
      @clear="$emit('clear', $event)"
    />
  </ul>
</template>
<script>
const TrayListItem = () => import('./assets-explorer-tray-list-item.vue')

export default {
  components: {
    TrayListItem
  },

  props: {
    items: {
      type: Array,
      required: true
    }
  }
}</script>
